<template>
  <div class="home">
    <homeNav></homeNav>
    <!-- 优质订单标题 -->
    <div class="title">
      <div class="box">
        <p class="name">优质订单</p>
        <p class="text">我们会即时与你对接，助你完成采购需求</p>
      </div>
    </div>
    <!-- 订单分类 -->
    <div class="ordClass">
      <!-- 两侧广告 -->
      <div class="advertisement" :style="{ top: fixedElementTop, left: '50px' }" v-if="advertisementleftSwitch">
        <p style="position: absolute;right: 10px;top: 5px;z-index: 999;">
          <i class="el-icon-close" style="font-size: 18px;color: #f5f8fd;" @click="advertisementleftSwitch = false"></i>
        </p>
        <el-carousel height="600px" indicator-position="none">
          <el-carousel-item v-for="(item, index) in advertising[5].image" :key="index">
            <a :href="advertising[5].url" v-if="advertising[5].url">
              <img :src="getimgsrc(item)" loading="lazy" alt="" style="width: 250px;height: 600px;">
            </a>
            <img :src="getimgsrc(item)" loading="lazy" alt="" style="width: 250px;height: 600px;" v-else>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 右边 -->
      <div class="advertisement" :style="{ top: fixedElementTop, right: '50px' }" v-if="advertisementrightSwitch">
        <p style="position: absolute;right: 10px;top: 5px;z-index: 999;">
          <i class="el-icon-close" style="font-size: 18px;color: #f5f8fd;"
            @click="advertisementrightSwitch = false"></i>
        </p>
        <el-carousel height="600px" indicator-position="none">
          <el-carousel-item v-for="(item, index) in advertising[6].image" :key="index">
            <a :href="advertising[6].url" v-if="advertising[6].url">
              <img :src="getimgsrc(item)" alt="" style="width: 250px;height: 600px;">
            </a>
            <img :src="getimgsrc(item)" alt="" style="width: 250px;height: 600px;" v-else>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="ordClassList">
        <ul class="ordClassListLi">
          <li v-for="(item, index) in catList" :key="index" @click="get_orderlist(item.category_id)">
            <p class="icon">
              <img :src="item.image" alt="" v-if="item.image">
              <el-skeleton-item variant="image" style="width: 40px; height: 40px;" v-else />
            </p>
            <p class="name" style="font-size: 16px;" :class="{ fencol: item.category_id == fen }">{{ item.name }}</p>
          </li>

        </ul>
        <div class="jiantou"></div>
      </div>
    </div>
    <!-- 订单列表 -->
    <div class="ordlist" v-loading="ordlistloading">
      <div class="listBox" v-if="orderpanList.length">
        <ul class="listUl">
          <li v-for="(item, index) in orderpanList" :key="index" @click="to_ordhome(item.id, item.ordertype)">
            <div class="top">
              <img :src="item.img_arr[0]" alt="">
              <div class="title">
                <p style="font-size: 15px;">{{ item.name }}</p>
                <p style="font-size: 13px;color:#6e6e6e;">{{ item.remark }}</p>
              </div>
            </div>
            <div class="bottom">
              <p class="num">加工量:<span style="color: #007ae5;">{{ item.num }}{{ item.unit }}</span></p>
              <p class="baoJia">立即报价</p>
            </div>
          </li>
          <li style="width: 260px;height: 0px;">
          </li>
          <li style="width: 260px;height: 0px;">
          </li>
          <li style="width: 260px;height: 0px;">
          </li>
        </ul>
        <p class="ordAll" @click="to_ordlist">查看全部订单</p>
      </div>
      <div class="listBox" v-else>
        <el-empty description="暂无订单"></el-empty>
        <!-- <p class="ordAll">查看全部订单</p> -->
      </div>
    </div>
    <!-- 推荐订单 -->
    <div class="tuiOrd">
      <div class="tuiOrdList">
        <div class="ordLogin">
          <p>推荐订单</p>
        </div>
        <div class="ordlist">
          <ul>
            <li v-for="(item, index) in orderpanList1" v-show="index < 6" :style="index < 3 ? 'border-bottom:none' : ''"
              :key="index" @click="to_ordhome(item.id, item.ordertype)">
              <p class="titlename">{{ item.name }}</p>
              <p class="text">{{ item.remark }}</p>
            </li>
          </ul>
        </div>
        <div class="zhanshi">
          <img src="../img/zhanshi.jpg" alt="">
        </div>
      </div>
    </div>

    <!-- 热门采购内容 -->
    <div class="remenListBox">
      <!-- 热门采购标题 -->
      <div class="title">
        <div class="box">
          <p class="name">热门采购</p>
          <p class="text">我们会即时与你对接，助你完成采购需求</p>
        </div>
      </div>
      <div class="remmenOrdList">
        <div class="zhanshiimg">
          <img src="../img/df78be48bffbe6defd55c143517563e.png" alt="">
        </div>
        <div class="listremen">
          <!-- <div class="nav">
            <p :class="{ bul: remenchaigouindex == 1 }">今日最新</p>
            <p>本周最新</p>
          </div> -->
          <ul class="ord">
            <li v-for="(item, index) in cgorderList" :key="index" @click="to_ordhome(item.id, item.ordertype)">
              <div style="height: 65px;">
                <p class="title">{{ item.name }}</p>
                <p class="ordcode">{{ item.remark }}</p>
              </div>
              <!-- <p class="adres">广东深郑</p> -->
              <p class="cz">
                <span>采购量:{{ item.num }}{{ item.unit }}</span>
                <span class="baojia">立即报价</span>
              </p>
            </li>
          </ul>
          <el-empty description="暂无订单" v-if="cgorderList.length == 0"></el-empty>
          <p class="ordAll" @click="to_ordlist">查看全部采购订单</p>
        </div>
      </div>
    </div>
    <!-- 优秀图纸标题 -->
    <div class="title">
      <div class="box">
        <p class="name">优秀图纸</p>
        <p class="text">我们会即时与你对接，助你完成采购需求</p>
      </div>
    </div>
    <div class="tuzhiBox">
      <div class="tuzhiList">
        <!-- <div class="nav_top">
          <ul>
            <li :class="{ bul: youxiuimgindex == 1 }">钣金加工</li>
            <li>机械设备</li>
            <li>模具制造</li>
            <li>热处理</li>
          </ul>
        </div> -->
        <!-- 列表 -->
        <div class="tuzhialllist">
          <ul>
            <li v-for="(item, index) in imgList" :key="index" @click="to_tuzhiinfo(item.id)">
              <img :src="item.img_arr[0]" alt="">
              <div style="margin-left: 10px;">
                <p class="tuzhiname">{{ item.name }}</p>
                <p class="tuzhititle">{{ item.remark }}</p>
              </div>
            </li>
            <li style="border:0; height:0;margin: 0;"></li>
            <li style="border:0; height:0;margin: 0;"></li>
            <li style="border:0; height:0;margin: 0;"></li>
          </ul>
          <el-empty description="暂无图纸" v-if="imgList.length == 0"></el-empty>

          <div class="ordAll" @click="to_ordlist">
            查看全部
          </div>

        </div>
      </div>
    </div>
    <!-- 中部广告图 -->
    <div class="banimgone">
      <el-carousel height="290px" direction="vertical">
        <el-carousel-item v-for="(item, index) in advertising[2].image" :key="index">
          <a :href="advertising[2].url" v-if="advertising[2].url">
            <img :src="getimgsrc(item)" alt="">
          </a>
          <img :src="getimgsrc(item)" alt="" v-else>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 优秀编程 -->
    <!-- <div class="title">
      <div class="box">
        <p class="name">优质编程</p>
        <p class="text">我们会即时与你对接，助你完成采购需求</p>
      </div>
    </div>

    <div class="youxiuBox">
      <div class="youxiulist">
        <ul>
          <li v-for="index in 4" :key="index">
            <p>优质编程</p>
            <img src="../img/5934d19e943522ab843bd301f5193e0.png" alt="">
            <p class="t">暂无数据</p>
            <p class="t">暂无数据</p>
            <p class="t">暂无数据</p>
            <p class="t">暂无数据</p>
          </li>
        </ul>
      </div>
      <div class="ordAll">
        查看全部
      </div>
    </div> -->
    <!-- 工程师入驻标题 -->
    <div class="ruzhuBox">
      <div class="title">
        <div class="box">
          <p class="name">工程师入驻</p>
          <p class="text">我们会即时与你对接，助你完成采购需求</p>
        </div>
      </div>

      <div class="gognchengshiList">
        <ul class="listgcs">
          <li v-for="(item, index) in gongchengshilist" :key="index" style="display: flex;align-items: center;">
            <img :src="getimgsrc(item.avatar)" alt="">
            <div class="titlebox">
              <p class="name">{{ item.nickname }}</p>
              <!-- <p class="jieshao">{{item.bio}}</p> -->
              <!-- <p class="looklook" @click="gongchengshiinfo(item.id)">立即查看</p> -->
            </div>
          </li>
        </ul>
        <el-empty description="暂无工程师" v-if="gongchengshilist.length == 0"></el-empty>

        <div class="ordAll" @click="to_gongchengshihome">
          查看全部
        </div>
      </div>
    </div>
    <!-- 优质采购 -->
    <div class="title">
      <div class="box">
        <p class="name">优质采购</p>
        <p class="text">我们会即时与你对接，助你完成采购需求</p>
      </div>
    </div>
    <!-- 优质采购 -->
    <div class="ordlist">
      <div class="listBox" style="background-color: white;">
        <ul class="listUl">
          <li v-for="(item, index) in yzcg_order_list" :key="index" @click="to_ordhome(item.id, item.ordertype)">
            <div class="top">
              <img :src="item.img_arr[0]" alt="">
              <div class="title">
                <p class="chaigouname" style="font-size: 16px;">{{ item.name }}</p>
                <p class="chaigoutitle">{{ item.remark }}</p>
              </div>
            </div>
            <div class="bottom">
              <p class="num"><span style="color: #007ae5;">{{ item.num }}{{ item.unit }}</span></p>
              <!-- <p class="">广东深郑</p> -->
            </div>
          </li>
          <li style="height:0;box-shadow:0;background-color:0"></li>
          <li style="height:0;box-shadow:0;background-color:0"></li>
          <li style="height:0;box-shadow:0;background-color:0"></li>
          <li style="height:0;box-shadow:0;background-color:0"></li>
        </ul>
        <el-empty description="暂无信息" v-if="yzcg_order_list.length == 0"></el-empty>

        <!-- <p class="ordAll">查看全部</p> -->
      </div>
    </div>
    <!-- 采购商家 -->
    <div class="remenListBox" style="background-color: #f5f8fd;">
      <!-- 采购商家标题 -->
      <div class="title">
        <div class="box">
          <p class="name">采购商家</p>
          <p class="text">我们会即时与你对接，助你完成采购需求</p>
        </div>
      </div>
      <div class="changjia">
        <div class="changjiaBox">
          <!-- <div class="changjialeft" style="background-color: white;">
            <p class="left_title">采购商家</p>
            <ul>
              <li class="listcahng" v-for="(item,index) in orderpriceList" :key="index" @click="to_shopJieShao(item.id)">
                <p class="index">{{ index+1 }}</p>  
                <div>
                  <p class="biao">{{ index+1 }}. {{ item.name }}</p>
                  <p class="zhuyin">{{ item.remark || '商家暂未添加'}}</p>
                </div>
              </li>
              <el-empty description="暂无厂家" v-if="orderpriceList.length == 0"></el-empty>
            </ul>

          </div> -->
          <div class="changjiaright">
            <div class="tuzhialllist">
              <ul>
                <li v-for="(item, index) in orderpriceList" :key="index" @click="to_shopJieShao(item.id)">
                  <img :src="item.img_arr[0]" alt="" style="object-fit:cover;">
                  <div>
                    <p class="tuzhiname">{{ item.name }}</p>
                    <p class="tuzhititle">{{ item.remark }}</p>
                    <p class="infor">
                      <span></span>
                      <span
                        style="color: #007ae5;border: #007ae5 1px solid;padding:0px 5px;border-radius: 20px;">进入主页</span>
                    </p>
                  </div>
                </li>
                <li style="border:0;"></li>
                <el-empty description="暂无厂家" v-if="orderpriceList.length == 0"></el-empty>
              </ul>

              <!-- <div class="ordAll">
                查看全部
              </div> -->

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 中部广告图 -->
    <div class="banimgone">
      <el-carousel height="290px" direction="vertical">
        <el-carousel-item v-for="(item, index) in advertising[3].image" :key="index">
          <a :href="advertising[3].url" v-if="advertising[3].url">
            <img :src="getimgsrc(item)" alt="">
          </a>
          <img :src="getimgsrc(item)" alt="" v-else>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 新入驻厂家 -->
    <!-- 新入驻厂家标题 -->
    <div class="title">
      <div class="box">
        <p class="name">新入驻厂家</p>
        <p class="text">我们会即时与你对接，助你完成采购需求</p>
      </div>
    </div>
    <div class="changjia">
      <div class="changjiaBox">
        <!-- <div class="changjialeft">
          <p class="left_title">新入驻厂家</p>
          <ul>
            <li class="listcahng" v-for="(item,index) in zxshopList" :key="index" @click="to_shopJieShao(item.id)">
              
              <div>
                <p class="biao">{{ index+1 }}. {{ item.name }}</p>
                <p class="zhuyin">主营产品:{{ item.zhuy || '商家暂未添加'}}</p>
              </div>
            </li>
          </ul>
          <el-empty description="暂无厂家" v-if="zxshopList.length == 0"></el-empty>
        </div> -->
        <div class="changjiaright">
          <div class="tuzhialllist">
            <ul>
              <li v-for="(item, index) in zxshopList" :key="index" @click="to_shopJieShao(item.id)">
                <img :src="item.logo" alt="" style="object-fit:contain;">
                <div>
                  <p class="tuzhiname">{{ item.name }}</p>
                  <p class="tuzhititle">主营产品:{{ item.zhuy }}</p>
                  <p class="infor">
                    <span></span>
                    <span
                      style="color: #007ae5;border: #007ae5 1px solid;padding:0px 5px;border-radius: 20px;">进入主页</span>
                  </p>
                </div>
              </li>
              <li style=" border:0;height: 0;"></li>
              <li style=" border:0;height: 0;"></li>
            </ul>


            <!-- <div class="ordAll">
              查看全部
            </div> -->

          </div>
        </div>

      </div>
      <el-empty description="暂无厂家" v-if="zxshopList.length == 0"></el-empty>
    </div>
    <!-- 优质厂家 -->
    <div class="youzhicahngjia">
      <div class="title">
        <div class="box">
          <p class="name">优质厂家</p>
          <p class="text">我们会即时与你对接，助你完成采购需求</p>
        </div>
      </div>
      <div class="listBox">
        <!-- <ul class="changjianav">
          <li :class="{ bul: youzhicahngjiaindex == index }" @click="shoplist_(item.id,index)" v-for="(item,index) in goodscat" :key="index" v-show="index<8">{{ item.name }}</li>
        </ul> -->
        <ul class="cahngjia">
          <li v-for="(item, index) in yzshopList" :key="index" @click="to_shopJieShao(item.id)">
            <p>{{ item.name }}</p>
            <el-image class="img" :src="getimgsrc(item.logo)"></el-image>
            <!-- <img :src="item.logo" alt="" class="img"> -->
            <!-- <p class="name">设备:阿森纳打卡机</p> -->
            <p class="name">主营产品:{{ item.zhuy }}</p>
            <!-- <p class="name">雇员数量:{{ item.num }}人</p>
            <p class="name">工厂面积:{{ item.gc_size_text }}</p> -->
            <p class="cla">
              <span></span>
              <span style="color: #007ae5;border: #007ae5 1px solid;padding: 3px 5px;border-radius: 20px;">点击查看</span>
            </p>
          </li>
          <li style="height:0;border:0"></li>
          <li style="height:0;border:0"></li>
          <li style="height:0;border:0"></li>
        </ul>
        <el-empty description="暂无厂家" v-if="yzshopList.length == 0"></el-empty>
        <div class="ordAll" @click="to_shophot">
          查看全部
        </div>
      </div>
    </div>
    <!-- 热门产品 -->
    <div class="youzhicahngjia" style="background-color: white;">
      <div class="title">
        <div class="box">
          <p class="name">热门产品</p>
          <p class="text">我们会即时与你对接，助你完成采购需求</p>
        </div>
      </div>
      <div class="listBox">
        <ul class="changjianav">
          <li :class="{ bul: reindex == index }" v-for="(item, index) in goodscat1" :key="index"
            @click="getgoodsList(item.id, index)">{{ item.name }}</li>
        </ul>
        <ul class="cahngjia" v-loading="remenloading">
          <li v-for="(item, index) in goodsList" :key="index">
            <!-- <p>{{ item.name }}</p> -->
            <el-image class="img" :src="getimgsrc(item.img_arr[0])"></el-image>
            <p class="name" style="height: 51px;">{{ item.name }}</p>
            <p class="cla">
              <span>￥{{ item.price }}/台</span>
              <span style="color: #007ae5;border: #007ae5 1px solid;padding: 3px 5px;border-radius: 20px;"
                @click="to_info(item.id)">点击查看</span>
            </p>
          </li>
          <li style="height:0;border:0"></li>
          <li style="height:0;border:0"></li>
          <li style="height:0;border:0"></li>
        </ul>
        <el-empty description="暂无产品" v-if="goodsList.length == 0"></el-empty>

        <!-- <div class="ordAll">
          查看全部
        </div> -->
      </div>
    </div>
    <!-- 新闻推荐 -->
    <div class="youzhicahngjia" style="margin-bottom: 20px;">
      <div class="title">
        <div class="box">
          <p class="name">新闻推荐</p>
          <p class="text">我们会即时与你对接，助你完成采购需求</p>
        </div>
      </div>
      <div class="newlist">
        <div class="left">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>精选新闻</span>
            </div>
            <div v-for="(item, index) in newlist" :key="index" v-show="index < 5" class="text item"
              @click="to_zhixunInfo(item.id)">
              <img :src="item.image" alt="">
              <div class="title" style="cursor: pointer;">
                <p class="titlename" style="font-size: 16px;font-weight: bold;">{{ item.name }}</p>
                <p class="titletext" style="font-size: 15px;">{{ item.content }}</p>
              </div>
            </div>
            <el-empty description="暂无新闻" v-if="newlist.length == 0"></el-empty>
          </el-card>
        </div>
        <div class="right">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>资讯快报</span>
            </div>
            <div v-for="(item, index) in newlist" :key="index" class="text item" @click="to_zhixunInfo(item.id)">
              <div class="title">
                <p class="titlename"><span>{{ (index + 1) + "、" }}</span> {{ item.name }}</p>
              </div>
            </div>
            <el-empty description="暂无咨询" v-if="newlist.length == 0"></el-empty>

          </el-card>
        </div>
      </div>
    </div>

    <!-- 招聘、回答、订单 -->
    <!-- <div class="title">
      <div class="box">
        <p class="name">人才招聘</p>
        <p class="text">我们会即时与你对接，助你完成采购需求</p>
      </div>
    </div>
    <div class="all">
      <div class="allBox">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>企业招聘</span>
          </div>
          <div class="flexwarp">
            <div class="item" v-for="(item, index) in hirelist" :key="index" @click="to_hireInfo(item.id)">
              <div class="bgcolor">
                <div class="zwxz">
                  <div class="position">
                    {{ item.name }}
                  </div>
                  <div class="salary">
                    {{ item.xinzi_d }}-{{ item.xinzi_g }}/月
                  </div>
                </div>
                <div class="tag">
                  <el-tag type="info">{{ item.edu_text }}</el-tag>
                </div>
                <div class="nameaddres">
                  <div class="name">
                    {{ item.shop_name }}
                  </div>
                  <div class="addres">
                    {{ item.province }}{{ item.city }}{{ item.district }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <el-empty description="暂无咨询" v-if="hirelist.length == 0"></el-empty>
        </el-card>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import homeNav from '@/components/homeNav.vue'  
export default {
  name: 'HomeView',
  data() {
    return {
      //订单列表加载中
      ordlistloading: false,
      //热门产品加载中
      remenloading: false,
      //分类换色
      fen: null,
      //热门采购
      remenchaigouindex: 1,
      //优秀图纸
      youxiuimgindex: 1,
      //优质厂家
      youzhicahngjiaindex: 1,
      //热门产品
      reindex: 1,
      // 优质订单分类
      catList: [],
      //招聘列表
      hirelist: [],
      // 订单列表
      orderpanList: [],
      orderpanList1: [],
      // 热门采购
      cgorderList: [],
      // 图纸列表
      imgList: [],
      // 优质采购
      yzcg_order_list: [],
      // 采购商家
      orderpriceList: [],
      // 最新入驻厂家
      zxshopList: [],
      // 优质入驻厂家
      yzshopList: [],
      // 厂家分类
      goodscat: [],
      // 商品分类
      goodscat1: [],
      // 商品分类
      goodsList: [],
      // 新闻列表
      newlist: [],
      //工程师列表
      gongchengshilist: [],
      //广告
      scrollHeight: 0, // 页面滚动高度
      fixedElementTop: '0px', // 初始固定元素的top值
      advertisementleftSwitch: true,//广告位列表
      advertisementrightSwitch: true,//广告位列表
      AdvertisingList: [],//广告位
    }
  },
  computed: {
    ...mapState(['advertising'])
  },
  components: {
    homeNav
  },
  mounted() {
    this.getAdvertisingList()
    this.getcatList()
    // 优质订单  推荐订单
    this.order_list()
    this.getimgList()
    this.getorderpriceList()
    this.getgoodsCat()
    this.getgoodsCat1()
    this.getGongchengshi()
    this.shoplist_()
    this.getnewlist()
    this.gethirelist()
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    //广告位列表
    getAdvertisingList() {
      this.$http.post('newquest/bannerList').then(res => {
        // console.log('广告信息', res);
        if (res.data.code == 1) {
          this.$store.commit('setAdvertising', res.data.data)
        }
      })
    },
    //广告位定位
    handleScroll() {
      // 获取页面滚动高度
      this.scrollHeight = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

      // 根据需要调整固定元素的top值
      if (this.scrollHeight >= 700) {
        // 当页面滚动高度达到200px时，修改固定元素的top值
        this.fixedElementTop = `${this.scrollHeight - 600}px`;
      } else {
        // 如果页面滚动高度小于200px，保持初始的top值
        this.fixedElementTop = '0px';
      }
    },
    //商家推荐
    to_shophot() {
      this.$router.push({
        path: '/shophot'
      })
    },
    //产品详情
    to_info(id) {
      this.$router.push({
        path: "/productdetails",
        query: {
          id: id
        }
      })
    },
    //跳转图纸订单详情
    to_tuzhiinfo(id) {
      this.$router.push({
        path: '/tuzhiinfo',
        query: {
          id
        }
      })
    },
    //跳转工程师详情
    gongchengshiinfo(id) {
      this.$router.push({
        path: 'gongchengshiinfo',
        query: {
          id
        }
      })
    },
    // 跳转招聘信息详情
    to_hireInfo(id) {
      this.$router.push({
        path: 'hireInfo',
        query: {
          id
        }
      })
    },

    //跳转订单订单大厅
    to_ordlist() {
      this.$router.push('ordhome')
    },
    //工程师查看更多
    to_gongchengshihome() {
      this.$router.push('gongchengshihome')
    },
    //工程师列表
    getGongchengshi() {
      this.$http.post('v1/web/index/zbcList', {
        page: 1,
        limit: 12,
      }).then(res => {
        // console.log(res,"工程师")
        this.gongchengshilist = res.data.data.data
      })
    },
    // 招聘列表
    gethirelist() {
      this.$http.post('/v1/web/index/jobList').then(res => {
        // console.log(res, "招聘")
        this.hirelist = res.data.data.data
      })
    },
    // v1/web/index/catList
    // 获取优质订单分类
    async getcatList() {
      const { data: res } = await this.$http.post('v1/web/index/catList')
      this.catList = res.data
      this.fen = this.catList[0].category_id
    },
    // v1/web/index/orderpanList
    // 获取订单列表
    // 状态（0：普通订单，1：推荐订单，2：热门采购，3：优质采购），条数，分类id，页数
    async getorderpanList(is_tui, limit = '', category_id = '', page = '') {
      let { data: res } = await this.$http.post('v1/web/index/orderpanList', {
        is_tui,
        limit,
        category_id,
        page
      })
      return res.data.data
    },
    async order_list() {
      // 优质订单
      this.orderpanList = await this.getorderpanList(1, 12)
      this.orderpanList1 = await this.getorderpanList(1, 12)
      this.cgorderList = await this.getorderpanList(2, 12)
      this.yzcg_order_list = await this.getorderpanList(3, 12)
      this.zxshopList = await this.getshopList(1, 1, 12)
    },
    // 优质订单点击分类
    async get_orderlist(id) {
      this.ordlistloading = true
      this.fen = id
      this.orderpanList = await this.getorderpanList(1, 12, id)
      this.ordlistloading = false
    },
    //v1/web/index/orderImgList
    // 获取优秀图纸
    async getimgList() {
      const { data: res } = await this.$http.post("v1/web/index/orderImgList", {
        limit: 12
      })
      this.imgList = res.data.data
    },
    // v1/web/index/orderpriceList
    // 采购商家
    async getorderpriceList() {
      const { data: res } = await this.$http.post('v1/web/index/orderpriceList', {
        limit: 12,
        page: 1,
      })
      this.orderpriceList = res.data.data
    },
    // v1/web/index/shopList
    // 最新/优质厂家
    async getshopList(page, flag, limit, category_id) {
      const { data: res } = await this.$http.post('v1/web/index/shopList', {
        page,
        flag,
        limit,
        category_id
      })
      return res.data.data
    },
    // 优质厂家
    async shoplist_(category_id = 73, inx) {
      console.log(category_id, inx)
      this.youzhicahngjiaindex = inx || 0
      this.yzshopList = []
      this.yzshopList = await this.getshopList(1, 2, 8)
    },
    // 厂家分类
    // v1/goods/goodsCat
    async getgoodsCat() {
      const { data: res } = await this.$http.post('v1/goods/goodsCat', {
        type: 'hangye'
      })
      this.goodscat = res.data
    },
    // 产品分类
    // v1/goods/goodsCat
    async getgoodsCat1() {
      const { data: res } = await this.$http.post('v1/goods/goodsCat', {
        type: 'goods'
      })
      this.goodscat1 = res.data

      this.getgoodsList(this.goodscat1[0].id, 0)
    },
    // 热门产品
    // v1/web/index/goodsList
    async getgoodsList(id, inx) {
      this.remenloading = true
      if (this.reindex == inx) return
      this.reindex = inx
      this.goodsList = []
      const { data: res } = await this.$http.post('v1/web/index/goodsList', {
        category_id: id,
        limit: 8,
        page: ''
      })
      this.goodsList = res.data.data
      this.remenloading = false
    },
    // 新闻推荐
    // v1/web/index/newList
    async getnewlist() {
      const { data: res } = await this.$http.post('v1/web/index/newList', {
        // page,
        // limit
      })
      this.newlist = res.data.data
    },
    //订单详情
    to_ordhome(id, type) {
      // console.log(id);
      this.$router.push({
        path: 'ordinfo',
        query: {
          id,
          type
        }
      })
    },
    //商家介绍
    to_shopJieShao(id) {
      this.$router.push({
        path: "shopjieshao",
        query: {
          id
        }
      })
    },
    //行业质询详情
    to_zhixunInfo(id) {
      this.$router.push({
        path: 'hangyeInfo',
        query: {
          id
        }
      })
    },

  }
}
</script>

<style scoped lang="scss">
li {
  list-style: none;
  cursor: pointer;
}

.fencol {
  color: #007ae5;
}

.bul {
  color: #007ae5;
  border-bottom: 2px solid #007ae5;
  padding-bottom: 3px;
}

.ordAll {
  cursor: pointer;
  border: 1px solid #007ae5;
  width: 150px;
  text-align: center;
  padding: 5px;
  border-radius: 20px;
  color: #007ae5;
  margin: 30px auto;
}

.title {
  width: 100%;

  .box {
    width: 1200px;
    margin: 35px auto;
    text-align: center;

    .name {
      font-size: 25px;
      font-weight: 600;
    }

    p {
      margin: 10px;
    }

    .text {
      font-size: 16px;
    }
  }

}

.ordClass {
  width: 100%;
  position: relative;

  .advertisement {
    width: 250px;
    height: 600px;
    position: absolute;
    top: 0px;
    background-color: #f7f7f7;
    z-index: 999;
    transition: all 0.5s;
  }

  .ordClassList {
    width: 1200px;
    margin: auto;
    border: 1px solid rgb(236, 236, 236);
    padding: 20px 10px;
    position: relative;

    .ordClassListLi {
      display: flex;
      width: 100%;
      justify-content: space-around;

      li {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      li:hover {
        // transition: all 0.2s;
        transform: scale(1.1);
      }

      .icon {
        text-align: center;
        // border: 1px solid rgb(236, 236, 236);
        border-radius: 50%;
        height: 35px;
        width: 35px;
        line-height: 35px;

        img {
          height: 20px;
          width: 20px;
        }
      }

      // .icon:hover{
      //   transition: all 0.3s;
      //   color: #007ae5;
      //   img {
      //     transition: all 0.2s;
      //     height:25px;
      //     width: 25px;
      //   }
      // }
      .name {
        text-align: center;
      }
    }

    .jiantou {
      position: absolute;
      bottom: 0px;
      left: 117px;
      width: 0px;
      height: 0px;
      border: 15px solid rgb(255, 255, 255);
      border-top-color: rgb(255, 255, 255);
      border-bottom-color: #f5f8fd;
      border-left-color: rgb(255, 255, 255);
      border-right-color: rgb(255, 255, 255);
    }
  }
}

.ordlist {
  width: 100%;

  .listBox {
    width: 1200px;
    margin: auto;
    background-color: #f5f8fd;
    padding: 20px 10px;

    .listUl {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      li:hover {
        transition: all 0.2s;
        transform: scale(1.1);
      }

      li {
        margin-bottom: 20px;
        width: 260px;
        height: 135px;
        background-color: white;
        box-shadow: 2px 2px 5px rgb(180 180 180);
        border-radius: 5px;

        .top {
          padding-top: 5px;
          display: flex;
          width: 240px;
          margin: 10px auto;

          img {
            height: 50px;
            width: 50px;
          }

          .title {
            font-size: 12px;
            margin-left: 10px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;

            .chaigouname {
              width: 180px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .chaigoutitle {
              color: #6e6e6e;
            }
          }
        }

        .bottom {
          border-top: 1px solid rgb(235, 235, 235);
          margin-top: 20px;
          display: flex;
          font-size: 12px;
          padding: 12px 5px;
          justify-content: space-between;

          .num {
            color: rgb(128, 128, 128);
          }

          .baoJia {
            color: white;
            background-color: #007ae5;
            padding: 2px 5px;
            border-radius: 20px;
          }
        }
      }
    }

    .ordAll {
      cursor: pointer;
      border: 1px solid #007ae5;
      width: 150px;
      text-align: center;
      padding: 5px;
      border-radius: 20px;
      color: #007ae5;
      margin: 30px auto;
    }
  }
}

.tuiOrd {
  margin-top: 40px;
  width: 100%;
  margin-bottom: 40px;

  .tuiOrdList {
    border: 1px solid rgb(236, 236, 236);
    padding: 10px;
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .ordLogin {
      text-align: center;
      background-color: #007ae5;
      color: white;
      width: 40px;
      padding: 20px 5px;
    }

    .ordlist {
      width: 800px;

      // border: 1px solid #007ae5;
      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          // margin: 10px 0px;
          text-align: center;
          border: 1px solid rgb(236, 236, 236);
          width: 250px;

          .titlename {
            font-size: 16px;
            padding: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .text {
            font-size: 14px;
            padding: 0px 5px 5px 5px;
            color: rgb(121, 121, 121);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .zhanshi {
      width: 300px;

      img {
        width: 300px;
        height: 111px;
      }
    }
  }
}

.remenListBox {
  padding: 1px 0px;
  width: 100%;
  background-color: #f5f8fd;

  .remmenOrdList {
    background-color: white;
    width: 1200px;
    margin: 20px auto;
    padding: 20px 10px;
    display: flex;

    // align-items: center;
    .zhanshiimg {
      height: 316px;
      width: 200px;
      margin-top: 50px;

      img {
        height: 316px;
        width: 200px;
      }
    }

    .listremen {
      margin-left: 20px;
      width: 1000px;

      .nav {
        display: flex;
        border-bottom: 1px solid #f5f8fd;
        padding-bottom: 3px;

        p {
          margin-left: 10px;
          padding: 5px 0px;
          // border-bottom: #007ae5 3px solid;
          border-radius: 2px;
        }
      }

      .ord {
        display: flex;
        flex-wrap: wrap;

        li {
          padding: 10px 5px;
          border: 1px solid #dbdbdb;
          width: 200px;
          margin: 10px;
          border-radius: 5px;

          .title {
            color: #007ae5;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .ordcode {
            font-size: 13px;
            color: #aaaaaa;
            overflow: hidden; //多出的隐藏
            text-overflow: ellipsis; //多出部分用...代替
            display: -webkit-box; //定义为盒子模型显示
            -webkit-line-clamp: 2; //用来限制在一个块元素显示的文本的行数
            -webkit-box-orient: vertical; //从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）
            // height: 36px;
            margin-top: 5px;
          }

          .adres {
            font-size: 13px;
            margin: 4px 0px;
          }

          .cz {
            border-top: 1px solid #dbdbdb;
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            padding-top: 5px;
            align-items: center;

            .baojia {
              background-color: #007ae5;
              color: white;
              padding: 2px 5px;
              border-radius: 20px;
            }
          }
        }

        li:hover {
          transition: all 0.2s;
          transform: scale(1.1);
        }
      }

      .ordAll {
        cursor: pointer;
        border: 1px solid #007ae5;
        width: 150px;
        text-align: center;
        padding: 5px;
        border-radius: 20px;
        color: #007ae5;
        margin: 30px auto;
      }
    }
  }
}

.tuzhiBox {
  width: 100%;

  .tuzhiList {
    width: 1200px;
    margin: auto;

    .nav_top {
      width: 100%;

      ul {
        display: flex;

        li {
          padding: 3px 10px;
          margin-right: 5px;
        }
      }
    }

    .tuzhialllist {
      width: 100%;
      margin-top: 10px;

      ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        li {
          padding: 5rpx;
          margin-bottom: 10px;
          border: 1px solid #aaaaaa;
          // border-top: #007ae5 2px solid;
          width: 260px;
          display: flex;
          align-items: center;
          border-radius: 5px;

          img {
            height: 100px;
            width: 100px;
          }

          .tuzhiname {
            font-size: 14px;
            margin-top: 5px;
            margin-left: 5px;
            width: 140px;
            white-space: nowrap;
            overflow: hidden; //溢出内容隐藏
            text-overflow: ellipsis; //文本溢出部分用省略号表示
            // display: -webkit-box; //特别显示模式
            // -webkit-line-clamp: 1; //行数
          }

          .tuzhititle {
            font-size: 12px;
            margin-top: 10px;
            color: #aaaaaa;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
        }

        li:hover {
          transition: all 0.2s;
          transform: scale(1.1);
        }
      }

      .ordAll {
        cursor: pointer;
        border: 1px solid #007ae5;
        width: 150px;
        text-align: center;
        padding: 5px;
        border-radius: 20px;
        color: #007ae5;
        margin: 30px auto;
      }
    }
  }
}


.banimgone {
  width: 1200px;
  margin: auto;

  img {
    height: 290px;
    width: 100%;
  }
}

.youxiuBox {
  width: 100%;

  .youxiulist {
    width: 1200px;
    margin: auto;

    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      li {
        width: 202px;
        height: 260px;
        border: 1px solid #aaaaaa;
        border-bottom: #007ae5 2px solid;

        img {
          width: 202px;
          height: 138px;
        }

        p:nth-child(1) {
          text-align: center;
        }

        .t {
          font-size: 13px;
          padding: 3px;
        }
      }
    }
  }
}

.ruzhuBox {
  width: 100%;
  background-color: #f5f8fd;
  padding-bottom: 30px;
  padding-top: 1px;

  .gognchengshiList {
    width: 1200px;
    margin: auto;

    .listgcs {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;

      li {
        display: flex;
        width: 260px;
        padding: 5px;
        // border: 1px solid #aaaaaa;
        background: white;
        margin: 10px;
        border-radius: 5px;
        box-shadow: 2px 3px 3px #f4f4f4;

        img {
          height: 100px;
          width: 100px;
          border-radius: 50%;
        }

        .titlebox {
          margin-left: 10px;

          p {
            margin-top: 5px;
          }

          .name {
            font-size: 17px;
            font-weight: 500;
            text-align: center;
          }

          .jieshao {
            font-size: 12px;
            color: #6e6e6e;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden; //溢出内容隐藏
            text-overflow: ellipsis; //文本溢出部分用省略号表示
            display: -webkit-box; //特别显示模式
            -webkit-line-clamp: 3; //行数
            line-clamp: 3;
            -webkit-box-orient: vertical; //盒子中内容竖直排列
          }

          .looklook {
            font-size: 12px;
            border: #007ae5 1px solid;
            color: #007ae5;
            width: 80px;
            text-align: center;
            border-radius: 20px;
            margin: 10px auto;
          }
        }
      }
    }
  }
}

.shoplist {
  li {
    padding: 5px;
    border-bottom: 1px solid #aaaaaa;

    .textbox {
      font-size: 12px;

      .text {
        color: #aaaaaa;
      }

      .name {
        font-size: 17px;
      }

      .see {
        border: #007ae5 solid 1px;
        width: 80px;
        color: #007ae5;
        text-align: center;
        margin: 5px;
        border-radius: 20px;
      }
    }
  }

}

.changjia {
  width: 100%;
  padding-bottom: 40px;

  .changjiaBox {
    width: 1200px;
    margin: auto;
    display: flex;

    .changjialeft {
      width: 260px;
      // height: 460px;
      background-color: #f5f8fd;

      .left_title {
        background-color: #007ae5;
        color: white;
        padding: 5px;
        text-align: center;
      }

      .listcahng {
        display: flex;
        width: 240px;
        margin: 5px auto;
        padding-bottom: 5px;
        border-bottom: 1px solid #aaaaaa;

        .index {
          background-color: #007ae5;
          color: white;
          height: 30px;
          width: 30px;
          line-height: 20px;
          text-align: center;
          margin-right: 5px;
        }

        .biao {
          font-size: 13px;
          margin-left: 3px;
          color: #6e6e6e;
        }

        .zhuyin {
          margin-left: 3px;
          font-size: 13px;
          color: #aaaaaa;
          overflow: hidden;
          text-overflow: ellipsis;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 2; //行数
          line-clamp: 2;
          -webkit-box-orient: vertical; //盒子中内容竖直排列
        }
      }
    }

    .changjiaright {
      .tuzhialllist {
        width: 100%;
        margin-left: 20px;

        ul {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          li {
            border-radius: 8px;
            overflow: hidden;
            padding: 5rpx;
            margin-bottom: 10px;
            border: 1px solid #aaaaaa;
            width: 260px;
            height: 122px;
            display: flex;

            // align-items: center;
            img {
              height: 122px;
              width: 110px;

            }

            .tuzhiname {
              font-size: 16px;
              width: 140px;
              margin-top: 5px;
              margin-left: 5px;
              white-space: nowrap;
              overflow: hidden; //溢出内容隐藏
              text-overflow: ellipsis; //文本溢出部分用省略号表示
              // display: -webkit-box; //特别显示模式
              // -webkit-line-clamp: 1; //行数
            }

            .tuzhititle {
              height: 48px;
              font-size: 12px;
              color: #aaaaaa;
              margin-left: 5px;
              margin-top: 7px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden; //溢出内容隐藏
              text-overflow: ellipsis; //文本溢出部分用省略号表示
              display: -webkit-box; //特别显示模式
              -webkit-line-clamp: 3; //行数
              line-clamp: 3;
              -webkit-box-orient: vertical; //盒子中内容竖直排列
            }

            .infor {
              display: flex;
              // border-top: 1px solid #aaaaaa;
              font-size: 13px;
              padding: 5px;
              margin-top: 5px;
            }
          }

          li:hover {
            transition: all 0.2s;
            transform: scale(1.1);
          }
        }

        .ordAll {
          cursor: pointer;
          border: 1px solid #007ae5;
          width: 150px;
          text-align: center;
          padding: 5px;
          border-radius: 20px;
          color: #007ae5;
          margin: 30px auto;
        }
      }
    }
  }
}

.youzhicahngjia {
  width: 100%;
  background-color: #f5f8fd;
  padding: 1px 0px;

  .listBox {
    width: 1200px;
    margin: auto;
    padding: 0px;

    .changjianav {
      display: flex;
      justify-content: space-between;
      margin: 20px;

      // font-weight: 600;
      li:hover {

        color: #007ae5;
      }
    }

    .cahngjia {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      li {
        width: 260px;
        border: 1px solid #dddddd;
        border-radius: 8px;
        overflow: hidden;
        // border-top: #007ae5 2px solid;
        text-align: center;
        margin: 10px;
        background-color: white;

        .img {
          height: 230px;
          width: 260px;
        }

        .name {
          height: 63px;
          padding: 0px 3px;
          font-size: 16px;
          margin-top: 10px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 3; //行数
          line-clamp: 3;
          -webkit-box-orient: vertical; //盒子中内容竖直排列
        }

        .cla {
          margin-top: 5px;
          border-top: 1px solid #aaaaaa;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 13px;
          padding: 6px;
          height: 38px;
        }
      }
    }
  }
}

.newlist {
  padding: 0px 0px;
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;

  .left {
    width: 900px;
    height: 559.5px;

    // border: #007ae5 1px solid;
    .text {
      display: flex;
      margin: 10px 0px;

      img {
        width: 150px;
        height: 80px;
      }

      .title {
        font-size: 12px;
        margin-left: 30px;
        margin-top: 10px;

        .titlename {
          color: #007ae5;
        }

        .titletext {
          margin-top: 10px;
          color: #6e6e6e;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 2; //行数
          line-clamp: 2;
          -webkit-box-orient: vertical; //盒子中内容竖直排列
        }
      }
    }
  }

  .right {
    width: 280px;
    height: 559.5px;

    .clearfix {
      color: #007ae5;
    }

    .text {
      font-size: 14px;

      .title {
        margin: 24.5px 0px;

        .titlename {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

    }
  }
}

.all {
  width: 100%;
  padding: 30px 0px;

  .allBox {
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;

    .box-card {
      width: 100%;

      .clearfix {
        color: #007ae5;
      }

      .flexwarp {
        display: flex;
        flex-wrap: wrap;

        .item {
          width: 33.3%;
          padding: 10px;
          box-sizing: border-box;
          transition: all .2s linear;

          .bgcolor {
            background-color: #f1f1f1;
            box-sizing: border-box;
            border-radius: 15px;

            .zwxz {
              display: flex;
              justify-content: space-between;
              font-size: 16px;
              padding: 20px;

              .position {
                color: #222;
              }

              .salary {
                color: #f86c4f;
              }
            }

            .tag {
              padding: 10px 20px;
            }

            .nameaddres {
              display: flex;
              justify-content: space-between;
              font-size: 13px;
              padding: 20px;
              color: #61687c;
              background: linear-gradient(90deg, #f5fcfc 0, #fcfbfa 100%);

              .name {
                width: 60%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .addres {
                width: 35%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }

        .item:hover {
          cursor: pointer;

          .bgcolor {
            box-shadow: 0 16px 40px 0 rgba(138, 149, 158, .2);

            .position {
              color: #10a5a6;
            }
          }



          .name {
            color: #10a5a6;
          }
        }
      }


    }
  }
}
</style>
